@use 'sass:math';
@use 'sass:color';

.state-container {
  .table {
    margin: 0;

    td,
    th {
      text-align: left;
      vertical-align: middle;
      border: none;
    }

    @mixin td_width($w) {
      width: $w;

      .has-wide_list & {
        // this makes width behave like max-width
        max-width: 0;
      }
    }

    .expense-icon-cell {
      min-width: 40px;
      width: 40px;
      max-width: 0; // very fixed width always
      padding: 10px;
      padding-right: 0;

      .transaction-icon {
        display: inline-block; // 30px wide
        width: 100%;
      }
    }

    .expense-amount_date-cell {
      text-align: right;
      @include td_width(14%);

      @media (max-width: $three_col-breakpoint) {
        .has-wide_list & {
          max-width: none;
        }
      }
    }

    .expense-username-cell {
      // have to do this otherwise text overflow won't work
      max-width: 0;
    }

    .expense-vat_title-cell {
      @include td_width(9%);
    }

    .expense-batch_error-cell {
      width: 14px; // the width of the error icon
      vertical-align: top;
      padding-left: 0;
      padding-right: 0;
    }

    .expense-expense_acc-cell {
      @include td_width(12%);
    }

    .expense-asset_acc-cell {
      @include td_width(12%);
    }

    // expense_acc + asset_acc joined together
    .expense-not_applicable-cell {
      @include td_width(12% + 12%);
    }

    .expense-cost_center-cell {
      @include td_width(12%);

      .expense-cost_center {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 0 0.4rem;
        overflow: hidden;
        // three lines of text max, so row height doesn't blow up
        max-height: 1.5rem * 3;

        span {
          flex-basis: calc(50% - 0.2rem);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .expense-last_updated-cell {
      text-align: right;
      @include td_width(10%);
    }

    .ellipsis,
    .ellipsis-cell {
      @include text_overflow;
    }

    // sum up remaining width-s after first 3 cols are rendered
    // so, group-header doesn't jump when it's tbody is hidden
    th.rest_space-cell {
      width: 9% + 12% + 12% + 12% + 10%;
    }

    td.na-cell {
      color: dimgray;
      font-style: italic;
    }

    .expense-links {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      a {
        display: inline-flex;
      }
    }
  }
}

.table.claims-header-table {
  background-color: transparent !important;
  user-select: none;
  grid-area: section-thead;

  &.collapse:not(.show) {
    display: none !important;
  }
  &.collapse.show {
    display: table !important;
  }

  .has-wide_list & {
    &.collapse.show {
      display: table !important;
    }

    &.collapse:not(.show) {
      display: none !important;
    }
  }

  td {
    vertical-align: bottom;
    font-size: 12px;
    padding-top: 0;
    padding-bottom: 5px;
    padding-right: 15px; // reserve some space for sorter arrow

    position: relative;
    color: hsl(0 0% 58%);
    font-weight: $f-normal;
    transition: color 0.1s;

    &[data-sort_by] {
      &:after {
        display: inline-block;
        font-size: 14px;
        line-height: 1;
        width: 1ch;
        text-align: center;
        vertical-align: middle;
        content: '↕';
        opacity: 0;
        transition:
          opacity 0.2s,
          color 0.1s;

        position: absolute;
        right: 4px;
        top: 1px;
      }

      &:hover {
        color: color.scale($text-gray, $lightness: 10%);
        &:after {
          opacity: 1;
        }
      }
    }
  }

  td.expense-username-cell {
    &:after {
      position: relative;
      right: -0.25rem;
      top: -2px;
    }
  }

  td[data-order] {
    color: hsl(0 0% 33%); // #535353
  }
  td[data-order='asc'] {
    &:after {
      opacity: 1;
      content: '↑';
      margin-top: -1px; // up arrow is special this way...
    }
  }
  td[data-order='desc'] {
    &:after {
      opacity: 1;
      content: '↓';
    }
  }
}

// used in 'approve all' modal, so top level
.expense-collection-header {
  display: inline-flex;
  gap: 1rem;
  align-items: center;

  .title-sum {
    vertical-align: middle;

    i,
    em {
      white-space: nowrap;
      font-style: normal;
    }
  }

  .sum-icon {
    vertical-align: middle;

    display: inline-flex;
    align-items: center;
    gap: 0.25em;
    white-space: nowrap;
  }
  .icon {
    width: 14px;
    height: 14px;
  }
  .sum,
  small {
    font-size: 13px;
    line-height: 1;
  }

  .icon {
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .personal-expenses-icon {
    background-image: url('~images/bezala_pay.png');
  }
  .personal-expenses-icon-accounted {
    background-image: url('~images/bezala_paid.png');
  }
  .company-expenses-icon {
    background-image: url('~images/credit_card_paid.png');
  }
}

.state-container {
  margin-bottom: 15px;
  border-radius: 5px;

  box-shadow: $bottom-box-shadow;

  &:before {
    @include cover-overscroll-pseudo;
    z-index: 1;
  }

  .empty-filter-result {
    background-color: white;
    display: none;
  }

  &.has-empty-filter {
    .empty-filter-result {
      display: block;
    }

    // no actions on empty collection
    .expenses-container-footer {
      display: none;
    }
  }

  .section-header {
    position: sticky;
    top: 10px;
    margin-top: -5px; // overscroll rounded corners

    background-color: $secondary;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 1;

    border-bottom: 1px solid $expense-border-color;

    display: grid;

    grid-template-rows: min-content;
    grid-template-columns: 1fr;
    grid-template-areas:
      'section-title'
      'section-filter'
      'section-thead';

    .has-wide_list & {
      grid-template-rows: #{math.div(42.5, 14)}rem; // matches seachbox height
      // all the space for section header, and then goes search
      grid-template-columns: max-content auto;
      grid-template-areas:
        'section-title section-filter'
        'section-thead section-thead';
    }

    p {
      margin: 0;
    }

    .expenses-filter-container {
      grid-area: section-filter;
      position: relative;
      padding: 5px 10px;
      margin-top: -5px;

      display: flex;
      justify-content: flex-start;

      .has-wide_list & {
        margin-top: 0;
        padding: 10px;
      }

      flex-wrap: nowrap;
      align-items: center;
      align-self: flex-start;

      // icon
      i {
        color: #535353;
        line-height: 1;
        cursor: text;
      }

      input.expenses-filter {
        font-size: 13px;
        appearance: none;
        border: none;
        outline: none;
        background: transparent;

        padding-left: 18px;
        margin-left: -14px;
        border-bottom: 1px solid transparent;

        width: 100%;
        .has-wide_list & {
          max-width: 250px;
        }

        &:focus {
          border-bottom-color: darken($secondary, 20%);
        }
      }
    }

    .expense-collection-header {
      padding: 10px;
      display: inline-flex;
      gap: 0.25rem 0.5rem;
      flex-wrap: wrap;

      .title-sum {
        i {
          font-size: 13px;
        }
        em {
          // effectively, <nowrap>...</nowrap>
          font-size: inherit;
        }
      }
    }

    .header-line {
      cursor: pointer;
      grid-area: section-title;
      align-self: center;
      margin: 0;
    }
  }
}

.tag-name {
  --bs-btn-bg: #{rgba($brand-color-green, 0.1)};
  --bs-btn-color: #{$brand-color-green};
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: calc(0.5rem + 1px);
  --bs-btn-font-weight: #{$f-light-bold};
  --bs-btn-line-height: 1rem;
  --bs-btn-hover-bg: #{rgba($brand-color-green, 0.2)};

  // parent row handles hover
  &:hover,
  &:active,
  &:focus {
    --bs-btn-hover-color: var(--bs-btn-color);
    --bs-btn-hover-bg: var(--bs-btn-bg);

    --bs-btn-active-color: var(--bs-btn-color);
    --bs-btn-active-bg: var(--bs-btn-bg);
  }

  // the one in the modal title
  .title-sum & {
    cursor: default;
    @include text-overflow;
    max-width: 25rem;
  }
}

.state-container {
  .wide_only-cell {
    display: none;
  }

  .has-wide_list & {
    .wide_only-cell {
      display: table-cell;
    }
  }

  @media (max-width: $three_col-breakpoint) {
    .has-wide_list & .wide_only-cell,
    .wide_only-cell {
      display: none;
    }
  }
}

.expenses-list-item {
  cursor: pointer;

  &,
  & .transaction-icon {
    transition: background-color 0.2s;
  }

  .transaction-icon {
    background-color: $expense-icon-gray;
  }

  &:hover {
    background-color: $expense-list-item-hover;
  }

  &.selected-item {
    &,
    &:hover {
      background-color: $expense-selected-bg-color;
    }

    .transaction-icon {
      background-color: $brand-color-green;
    }
  }
}

.group-header {
  cursor: pointer;

  .btn,
  .btn-link {
    font-weight: $f-light-bold;
  }
  .btn-link {
    padding: 0;
  }
}

.grouped-expenses {
  thead.group-header {
    display: none;

    transition: background-color 0.2s;

    th {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .transaction-icon {
      background-color: $expense-icon-gray;
    }

    .tag-name {
      @include text-overflow;
      max-width: 100%;
      font-size: inherit;

      &.is-narrow {
        max-width: calc(100% - 90px); // reserve some space for 'Approve'
      }
    }

    // summary header gets the same hover as regular expense
    &:hover {
      background-color: $expense-list-item-hover;
    }
  }

  tbody {
    &.is-hidden {
      display: none;
    }
  }

  // a bit darker (than hover) color for borders
  $darker-expense-border: color.scale($expense-list-item-hover, $lightness: -5%);
  &.has-tag {
    --expense-border-color: #{$expense-border-color};
    border-bottom: 1px solid var(--expense-border-color);

    &:only-child {
      border-bottom: 0;
    }

    // summaries are the same white as expenses at first
    background-color: white;

    // summaries have hover color for whole block
    &:hover {
      --expense-border-color: #{$darker-expense-border};
      background-color: $expense-list-item-hover;
    }
    // so otherwise white table needs to be transparent
    table {
      background-color: transparent !important;
    }

    // we need a bit darker colors for hovering expenses in summary
    $darker-hover: color.scale($expense-list-item-hover, $lightness: -3%);
    thead.group-header {
      display: table-header-group;

      &:hover {
        background-color: $darker-hover;
      }
    }

    .expenses-list-item {
      &:hover {
        background-color: $darker-hover;
      }
    }
  }

  $summary-selected-color-darker: color.scale($summary-selected-color, $lightness: -8%);
  // implies .has-tag
  &.selected-group {
    &,
    &:hover {
      --expense-border-color: #{$darker-expense-border};
    }

    thead.group-header {
      background-color: $summary-selected-color;

      &:hover {
        background-color: $summary-selected-color-darker;
      }
    }

    &:not(.with-child) {
      thead.group-header {
        background-color: $summary-selected-color-darker;
      }
    }

    .transaction-icon,
    thead.group-header .transaction-icon {
      background-color: $brand-color-green;
    }

    .expenses-list-item {
      background-color: $summary-selected-color;

      &:hover {
        background-color: $summary-selected-color-darker;
      }

      &.selected-item {
        background-color: $summary-selected-color-darker;
      }
    }

    &.with-child {
      .transaction-icon {
        background-color: color.adjust($summary-selected-color, $lightness: -45%);
      }

      .expenses-list-item {
        &.selected-item {
          background-color: $summary-selected-color-darker;

          .transaction-icon {
            background-color: $brand-color-green;
          }
        }
      }
    }
  }
}

.list-item-description {
  max-height: none;
  word-break: break-word;

  @include clamp_lines(2);
  text-overflow: ellipsis;
  overflow: hidden;
}

.connected-budget-link {
  vertical-align: middle;
}

@each $locale in $locales_list {
  .flag-#{$locale} {
    background-image: url('~images/flags/#{$locale}.svg');
    background-repeat: no-repeat;
    width: 17px;
    height: 13px;
    display: inline-block;
    border-radius: 2px;
  }
}

%approve-btn-link {
  color: $brand-color-green;
  font-weight: $f-light-bold;
  text-decoration: none;
  line-height: 2rem;
  letter-spacing: 0;
  padding: 5px 15px;
  font-size: 14px;
}
.approve-summary,
.approve-all {
  @extend %approve-btn-link;
}

.expenses-container-footer {
  background: white;
  min-height: 5px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: flex-end;
  margin-top: -1px; // eat parent's border if any (when has-tag is last)
  border-top: 1px solid $expense-border-color;

  .approve-link {
    display: inline-flex;
    flex-grow: 0;
  }
}

.load-more-button {
  min-width: min-content;
  max-width: 300px;
  width: 33%;
  margin: 2rem auto;
  display: block;
}

.everything_is_empty {
  text-align: center;
  margin: 3rem 1rem;
  font-size: 16px;
}
