.batches-grid {
  table tr td {
    vertical-align: top;
    white-space: normal;
    overflow: visible;
  }

  .other-docs-link {
    display: block;
  }

  .dropdown-container {
    --dropdown-display: none;

    position: relative;
    display: flex;
    align-items: center;

    &.is-active {
      --dropdown-display: block;

      .dropdown-container-chevron:after {
        transform: rotate(180deg);
      }
    }

    .dropdown-container-chevron {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      width: 1.5em;
      height: 1.5em;

      &:after {
        content: '';
        border-right: 0.25em solid transparent;
        border-left: 0.25em solid transparent;
        border-top: 0.25em solid;
        border-bottom: 0;
      }
    }

    .dropdown-container-actions {
      position: absolute;
      top: 100%;
      left: -8px;
      z-index: 1;
      white-space: nowrap;
      padding: 5px;

      background-color: white;
      border-radius: 5px;
      box-shadow: rgba(black, 0.11) 0 0 8px 0;
      display: var(--dropdown-display);
    }
  }

  // show dropdown-actions above the chevron for the latest table row, so
  // we don't get scrollbars around the table
  table tbody tr:last-child {
    .dropdown-container-actions {
      top: auto;
      bottom: 100%;
    }
  }
}
