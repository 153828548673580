@use 'sass:map';
// in BS5 1rem assumes the browser default, typically `16px`
// but we have 1rem equal to 14px our body font-weight
// TODO: consider refactor to have root & base font-size 1rem equal 16px

// roboto font sizes
$f-thin: 300;
$f-normal: 400;
$f-light-bold: 500;
$f-bold: 700;

$locales_list: en fi sv de;

$brand-color: #55b973;
$widget-body: #fff;
$brand-color-dark: #788c43;
$brand-color-green: #007476;

$menu-text-color: #0b132b;
$menu-hover-bg: #888098;

$text-gray: hsl(0 0% 33%); // #535353

$remove-red: #d85b65;

// list view state colors
$expense-icon-gray: #888098;
$expense-list-item-hover: #f6f8ff;
$expense-selected-bg-color: #e6f1f1;
// same color for selected summary as for the expense
$summary-selected-color: $expense-selected-bg-color;
$expense-border-color: #eaebed;

$font-family-sans-serif: Roboto, 'HelveticaNeue-Light', 'Helvetica Neue Light',
  'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif !default;
$font-family-serif: Georgia, 'Times New Roman', Times, serif !default;

$body-color: #333333;
$body-bg-color: #f8f8f8;

$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

$headings-font-family: inherit !default;
$headings-font-weight: $f-light-bold !default;
$headings-color: inherit !default;

$input-btn-font-family: inherit !default;

$caret-width: 0.25em;
$caret-vertical-align: 0.1em;

// colors

$primary: #219b6a;
$secondary: #edeff0;
$danger: #e85062;
$success: #5cb85c;
$warning: #f0ad4e;
$info: #5bc0de;

// layout

$header-height: 53px;
$actions-bar-height: 60px;
$one-col-padding: 1.5rem;
// max organic width: sv 335px, fi 340px, en 413px
// en is so wide because of "Scheduled to be sent to your bookkeeper"
$sidebar-width: 340px;

// breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1840px
);

// when we change from 3col to 1col
$three_col-breakpoint: map.get($grid-breakpoints, 'md');
// when we change from 2col to col2 under col1
$two_col-breakpoint: map.get($grid-breakpoints, 'lg');

$dropdown-min-width: 11.4286rem;
$dropdown-border-color: transparent;
$dropdown-border-radius: 0.2857rem;
$dropdown-border-width: 0rem;
$dropdown-font-size: 1.1429rem;

$dropdown-link-color: #333333;
$dropdown-link-hover-color: #262626;
$dropdown-link-hover-bg: #e8e8e8;
$dropdown-link-active-color: #333333;

$dropdown-item-padding-y: 0.2143rem;
$dropdown-item-padding-x: 1.4286rem;
$dropdown-spacer: 0rem;

$navbar-padding-y: 0.6429rem;
$navbar-padding-x: 0.6429rem;

$nav-pills-link-active-bg: $brand-color;

$modal-md: 600px;

// shadows

$shadow-color: rgba(33, 35, 38, 0.3);
$bottom-box-shadow: $shadow-color 0px 15px 10px -16px;
// left, right, bottom
$three-sided-box-shadow: $shadow-color 0px 10px 15px -10px;
