/* TODO: consider refactor: either remove this code or leave @media specific code */

.upload-btn {
  display: none;
}

.upload-page {
  display: none;
}

@media (max-width: 768px) {
  #list-container > div,
  #form-container > div,
  #image-container > div {
    border: 0;
  }
  .create-menu.dropdown-menu {
    left: 0px;
  }
  // this interferes with layout
  #form-container,
  #image-container {
    display: none;

    &.active {
      display: block;
      // has to have bg color, otherwise first two columns are visible
      background-color: $body-bg-color;
      z-index: 1;
    }
  }

  #form-container {
    padding-right: 5px !important;
  }

  #image-container > div {
    padding: 4px;
    padding-top: 10px;
  }

  .s-transaction {
    .back-btn {
      font-size: 26px;
      display: block;
      color: dimgray;
    }

    h1 {
      text-align: center;
      margin: 0;
      padding: 0;
    }
  }

  .upload-page {
    display: block;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #edeff0;
    margin-bottom: 10px;
  }

  .upload-btn {
    display: block;
    padding: 10px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #f9f9f9;
    font-size: 1.5em;
    margin-bottom: 10px;
  }
}
