/* Preloader */

.preloader {
  position: fixed;
  left: 0;
  right: 0;
  top: $header-height;
  bottom: 0;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up($name: 'xxl') {
    left: $sidebar-width;
  }
}

.preloader-image-container {
  $size: 32px;
  width: $size;
  height: $size;
  @include preloader-background;
}

.column-preloader {
  position: relative;
  padding: 3em 1em;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
