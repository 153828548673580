@mixin receipt-form-header {
  padding: 5px 10px;
  border-bottom: 1px solid #f9f9f9;
  background: #f9f9f9;
  font-size: 13px;
  cursor: default;
  @media (max-width: 768px) {
    text-align: center;
  }
}

@mixin page-header {
  font-size: 16px;
  font-weight: $f-light-bold;
  color: $text-gray;
}

@mixin preloader-background($bg-size: contain) {
  background-image: url('~images/preloader.gif');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: $bg-size;
}

@mixin cover-overscroll-pseudo(
  $bg: $body-bg-color,
  $height: 10px,
  $z-index: unset,
  $width: 100%
) {
  content: '';
  display: block;
  position: sticky;
  top: 0;

  z-index: $z-index;
  width: $width;
  background-color: $bg;

  $cover-rounded-corners: 5px;
  height: $height + $cover-rounded-corners;

  .embedded & {
    background-color: white;
  }
}

@mixin safari15 {
  // https://stackoverflow.com/a/25975282
  @media not all and (min-resolution: 0.001dpcm) {
    @content;
  }
}

@mixin clamp_lines($lines: 1) {
  // show no more than $lines of text
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin text_overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin hide_number_spinner() {
  appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
}
