.reports-table {
  td:first-child,
  td:last-child {
    white-space: nowrap;
  }
}
.f-table-box .reports-table {
  td:last-child {
    width: auto;
  }
}
