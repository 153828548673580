html.devise {
  overflow: auto;
  height: auto;

  body {
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;

    background-color: $brand-color-green;
    background-image: url('~images/background.png');

    .container {
      margin: 3rem auto;
    }

    &.is-mepco {
      background-color: #9be4e2;
      background-image: url('~images/mepco/background.png');

      a {
        color: #005050;
      }
    }

    .mepco-logo {
      display: block;
      margin: 2em auto 0;
      // so trailing a+ on the logo doesn't shift the visual centering
      transform: translateX(10px);
    }
  }

  .alert {
    max-width: 450px;
    margin: auto;
    margin-bottom: 20px;
  }
}

.signin-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  #visma_invite_form & {
    justify-content: space-around;
  }

  $box-shadow-props: 1px 1px 1px 1px;
  a {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 120px;
    height: 40px;
    padding: 0 16px;

    color: rgba(black, 0.5);
    text-decoration: none;

    border-radius: 2px;
    box-shadow: $box-shadow-props rgba(black, 0.11);

    // icon + text
    &:after {
      display: inline-flex;
      color: inherit;
      text-decoration: inherit;

      width: max-content;
      min-height: 18px;
      background-size: 18px 18px;
      background-position: 0 center;
      background-repeat: no-repeat;

      padding-left: calc(16px + 0.75rem);
    }

    &:hover {
      text-decoration: inherit;
      box-shadow: $box-shadow-props rgba(black, 0.22);
      color: rgba(black, 0.75);
    }

    &:active {
      transform: translate(1px, 1px);
    }
  }

  @media (max-width: 420px) {
    flex-direction: column;
    align-items: center;

    a {
      width: 44%;
    }
  }

  @media (max-width: 320px) {
    a {
      width: 100%;
    }
  }

  .google-signin-btn {
    &:after {
      background-image: url('~images/oauth-logos/google.svg');
      content: 'Google';
    }
  }

  .microsoft-signin-btn {
    &:after {
      background-image: url('~images/oauth-logos/microsoft.svg');
      content: 'Microsoft';
    }
  }

  .okta-signin-btn {
    &:after {
      background-image: url('~images/oauth-logos/okta.svg');
      content: 'Okta';
    }
  }
}
