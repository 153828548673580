.tax-card-image {
  width: 100%;
}

.integrations-list {
  padding-left: 20px;
  p {
    color: $brand-color-green;
  }
}

.toggle-ssn-display {
  right: 1em;
  top: 0.5em;
  color: $brand-color-green;
  cursor: pointer;
}
