.dropzone {
  background-color: #fff;
  border: 1px dashed #007aff;
  border-radius: 5px;
  color: #444343;
  font-weight: $f-bold;
  font-size: 20px;

  &#image_dropzone {
    min-height: 360px;
    &.dz-drag-hover {
      border-color: $brand-color-green;
    }
  }

  &#documents_dropzone {
    min-height: 100px;
  }
}
.dropzone .dz-default.dz-message {
  padding: 20px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: $f-normal;
  font-family: Roboto, Arial, sans-serif;

  button {
    border: none;
    background: transparent;
  }

  span {
    display: block;
  }

  .dropzone_helper {
    font-weight: $f-thin;
  }

  .error {
    background: #ffc8c8;
    border-radius: 4px;
    display: none;
    font-weight: $f-thin;
    padding: 10px;
    margin: 5px auto;
    text-align: center;
    font-size: 14px;
  }
}
