// account/user breakdown table
.table.expense-summary-table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  border-top: 1px solid $secondary;

  td,
  th {
    white-space: nowrap;
    border-right: 1px solid $secondary;
  }

  .sticky-col {
    position: sticky;
    left: 0;
    text-align: left;
    padding-left: 1.5rem;
    background-color: white;
    border-right: 1px solid hsl(0, 0%, 82%);
  }

  td,
  th {
    text-align: right; // all but first
    &:last-child {
      padding-right: 1.5rem;
    }
  }

  thead {
    tr th {
      font-weight: $f-normal;

      // 'Account - User' header
      &:first-child {
        color: dimgray;
        width: 33%;
      }

      // username header
      width: 10%;
    }
  }

  tbody {
    tr:hover {
      td,
      td.sticky-col {
        background-color: hsl(0, 0%, 98%);
      }
    }

    .text-overflow {
      @include text_overflow();
      display: inline-block;
      vertical-align: middle;

      &.name {
        max-width: 20ch;
      }
      &.code {
        max-width: 10ch;
      }
    }
  }

  tfoot {
    tr {
      font-weight: $f-normal;

      td {
        color: dimgray;
      }
      td,
      td.sticky-col {
        background-color: hsl(0, 0%, 95%);
      }
    }
  }
}

// approve summary modal
.modal-dialog:has(.expense-summary-modal) {
  max-width: 800px;
}

.expense-summary-modal {
  .expense-collection-header {
    .icon {
      margin-left: 5px;
      width: 15px;
      height: 15px;
    }

    .sum {
      font-size: 15px;
      color: #535353;
    }
  }

  .modal-body {
    padding: 0;
  }

  .expense-summary-table {
    border: none;
  }
}

// expense summary page
.expense-summary-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .section-body-box {
    flex-grow: 1;
    background-color: white;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .infobox {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    width: fit-content;
    height: fit-content;

    border: 1px solid hsl(0, 0%, 90%);
    background-color: hsl(0, 0%, 98%);
    border-radius: 0.5rem;
    align-items: flex-start;

    overflow-x: hidden; // so long tags are not breaking out of the box

    > i {
      align-self: flex-start;
      font-size: 24px;
      line-height: 1;
      color: hsl(0, 0%, 70%);
    }

    .infobox-body {
      overflow-x: hidden; // so long tags are not breaking out of the box
    }

    .tag-name {
      min-width: 0;
      max-width: 30ch;
      display: inline-block;
      vertical-align: middle;
      @include text_overflow();
    }
  }

  .expense-summary-header {
    border-radius: 5px 5px 0 0;
    align-items: flex-start;
    justify-content: end; // we want buttons on the right

    // we _have to_ to drill down `min-width: 0` for text-overflow to work
    // https://css-tricks.com/flexbox-truncated-text/
    .green-header-details {
      height: 32px; // match first row of buttons
      min-width: 0;
      flex-shrink: 1; // can't have it growing coz we want text overflow

      h1 {
        min-width: 0;

        .expense-collection-header {
          min-width: 0;

          .tag-name {
            min-width: 0;
            max-width: 45ch;
            @include text_overflow();

            background-color: rgba($brand-color-green, 0.1);
            padding: 0.25rem 0.5rem;
            border-radius: 4px;
          }
        }
      }
    }

    .expense-summary-header-right {
      align-self: center;
      // so shrink: 1 green-header-details can still eat all the space and
      // push the buttons to wrap in their container
      flex-shrink: 5;
    }
  }

  .create-expense-links {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: flex-end;

    .btn:not(._selector_weight_) {
      &[disabled] {
        animation: none;
      }

      font-size: 14px;

      > i {
        width: 24px;
      }

      // prettier-ignore
      & {
        &:nth-child(1) { animation-delay: calc(.3s + 1 * .3s) }
        &:nth-child(2) { animation-delay: calc(.3s + 2 * .3s) }
        &:nth-child(3) { animation-delay: calc(.3s + 3 * .3s) }
        &:nth-child(4) { animation-delay: calc(.3s + 4 * .3s) }
      }

      &:active {
        transform: translate(1px, 1px);
      }
    }
  }

  .page-buttons {
    position: sticky;
    // so it doesn't jump when parent is scrolled to very bottom
    bottom: 3px;
    // we don't want to reserve space for 1st column for summaries
    // they always have expenses list (unlike other views with .page-buttons)
    --page-buttons-left-space: 0px;
  }
}
