li:hover,
.select2-container--bootstrap .select2-results__option--highlighted[aria-selected] {
  a.details {
    color: #006400;
  }
}

.receipt-for-bill-line.unapproved {
  color: #ff0000;
}
